import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useRecoilState } from 'recoil';

import Layout from 'layout';
// UI
import LoadingPage from 'ui/LoadingPage';
// Import from State
import { profileState, isLoggedIn as loginState, configurationState } from 'state';
// Auth Function
import { checkLoginStatus } from 'func/auth';

import { getBackendStatus } from 'ajax/clientError';

function Google() {
  const [profile, setProfile] = useRecoilState(profileState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [, setConfiguration] = useRecoilState(configurationState);

  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    // Check the Valid Token
    if (profile?.role?.value === 'Guest') {
      navigate('/guest/booking/');
      return;
    }
    if (isLoggedIn) return;
    initialize();
  }, []);

  const checkStatus = async () => {
    const response = await getBackendStatus();
    console.log(response);
  };

  return (
    <>
      <LoadingPage hide={isLoggedIn} />
      {profile && (
        <Layout showLogoutButton>
          <div className="w-full">
            <div className="pl-4 pr-4 pt-6 md:pl-8 md:pr-8 xl:pr-16 mb-20">
              <button type="button" onClick={checkStatus} className="bg-primary-red text-white p-2 rounded-md">
                Check Backend Status
              </button>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}

export default Google;
