import apiClient from '../apiClient';

// Create New Customisation
export async function registerClientError(formData) {
  try {
    const request = await apiClient.post('/clienterror/register', {
      value: JSON.stringify(formData)
    });
    return request;
  } catch (error) {
    return error.request;
  }
}

// Get backend status
export async function getBackendStatus() {
  const request = await apiClient.get('/status');
  return request;
}
